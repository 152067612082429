
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import Meta from 'vue-meta'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueObserveVisibility from 'vue-observe-visibility'
import VScrollLock from 'v-scroll-lock'
import ImageHelper from './components/ImageHelper.vue'
import GlideHelper from './components/GlideHelper.vue'
import App from './components/App.vue'


Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VScrollLock)
Vue.component('image-helper', ImageHelper)
Vue.component('glide-helper', GlideHelper)

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

// work in progress
// router.beforeEach((to, from, next) => {
//   store.commit('setLoaded', false)
//   next();
// });

new Vue({
	router,
	store,
	render: createElement => createElement(App)
}).$mount('#app');