<template>
  <div class="secondary-media" @mouseover="hover = true" @mouseleave="hover = false">
    <h4 v-if="filter !== 'secondary'" class="title">{{title || filter}}</h4>
    <div class="col sm-2">
      <div class="active-media is-cover sixteen-nine">
        <transition name="fade-long">
          <video-helper 
            v-if="isVideo(media[activeIndex])" 
            :video="media[activeIndex].src" 
            :secondary="pauseOnLoad"
            :key="activeIndex">
          </video-helper>
          <image-helper 
            v-else 
            :src="media[activeIndex]" 
            :key="activeIndex" 
            :fade="true">
          </image-helper>
        </transition>
      </div>
      <div v-if="showThumbs" class="thumbnail-grid">
        <div v-for="(thumb, index) in media" 
             class="img-wrapper is-cover one-one" 
             :class="{'active' : activeIndex == index, 'is-video' : isVideo(thumb)}"
             @click="activeIndex = index">
          <image-helper :src="thumbSrc(thumb)"/>
        </div>
      </div>
    </div> 

  </div>
</template>


<script>
  import VideoHelper from "~/components/VideoHelper.vue";

  export default {
    components: { VideoHelper },
    props:{
      project: Object,
      filter: String,
      title: String,
      requireHover: Boolean
    },
    data(){
      return {
        activeIndex: 0,
        watchVisibility: false,
        pauseOnLoad: true,
        hover: false
      }
    },
    computed: {
      projectMedia() {
        return this.$projectMedia(this.project)
      },
      videos() {
        let output = this.projectMedia[this.filter].video
        return (output.length) ? output : false
      },
      images() {
        let output = this.projectMedia[this.filter].image
        return (output.length) ? output : false
      },
      media() {
        let media = []
        let heroImageCategory = '';

        if(this.project.heroImageGroup) {
          heroImageCategory = this.project.heroImageGroup
        }
       
        if (this.videos) {
          media = media.concat(this.videos)
        }

        // Inject hero image into the specified heroImageGroup or use the previous logic
        if (this.projectMedia.hero.video.length) {
          if (heroImageCategory) {
            if (this.filter === heroImageCategory) {
              media = media.concat(this.projectMedia.hero.image[0])
            }
          } else {
            let firstCategory
            Object.keys(this.projectMedia).forEach(function (key, index) {
              if (index === 1) {
                firstCategory = key
              }
            })
            if (this.filter === firstCategory) {
              media = media.concat(this.projectMedia.hero.image[0])
            }
          }
        }

        if (this.images) {
          media = media.concat(this.images)
        }
        return media
      },
      showThumbs() {
        return this.images.length > 1
      },
    },
    methods: {
      isVideo(thumb) {
       return typeof thumb === 'object'
      },
      thumbSrc(thumb) {
        return (this.isVideo(thumb)) ? thumb.poster : thumb
      }
    },
    watch: {
      activeIndex() {
        this.pauseOnLoad = false
      }
    },
    created() {
      window.addEventListener('keydown', (e) => {
        if((this.requireHover && this.hover) || !this.requireHover) {
          if (e.key == 'ArrowLeft') {
            if(this.activeIndex === 0) {
              this.activeIndex = this.media.length -1
            } else {
              this.activeIndex--
            }
          }
          if (e.key == 'ArrowRight') {
            if(this.activeIndex === this.media.length -1) {
              this.activeIndex = 0
            } else {
              this.activeIndex++
            }
          }
        }
      });
    }
  }
</script>