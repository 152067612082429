<template>
  <div class="hero is-cover sixteen-nine" ref="hero" :style="{'opacity':heroOpacity}">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loaded: false,
        heroOpacity: 1,
      }
    },
    computed: {
    },
    methods: {
      calcHeroOpacity() { 
        if(this.loaded) {
          let scrollPos = window.pageYOffset || document.documentElement.scrollTop
          let heroHeight = this.$refs.hero.clientHeight
          let opacityTarget = 33
          let step = heroHeight/(100 - opacityTarget) // opacity to fade to
          let output = (100 - (scrollPos / step)).toFixed()

          if(output > opacityTarget) {
            if(output == 100) {
              this.heroOpacity = 1
            } else {
              this.heroOpacity = output.padStart(4, '0.')
            }
          }
        }
      },
    },
    mounted() {
      this.loaded = true
    },
    created() {
      window.addEventListener('scroll', this.calcHeroOpacity);
    },
    destroyed() {
      window.removeEventListener('scroll', this.calcHeroOpacity);
    },
  }
</script>