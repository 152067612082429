
import Home from './components/pages/Home'
import Capability from './components/pages/Capability'
import Casestudy from './components/pages/Casestudy'
import ErrorPage from './components/pages/Error'


let routes = [
    
    {   
        path: '/',
        name: 'home',
        component: Home,
        meta: { 
            title: 'Home', 
            description: 'Default description', 
        }
    },
    {   
        path: '/residential', 
        name: 'residential',
        component: Capability,
        meta: { 
            title: 'Residential Capability', 
            description: 'Hughes Development has a rich history of residential development, with a portfolio that includes some of the country’s most established and sought-after communities.', 
        }
    },
    {   
        path: '/commercial', 
        name: 'commercial',
        component: Capability,
        meta: { 
            title: 'Commercial Capability', 
            description: 'From multinational companies to local businesses, medical centres to shopping hubs, car parks to commercial precincts — our commercial and industrial capabilities run the gamut of what’s required in today’s competitive landscape.', 
        },
    },
    {
        path: '/commercial/:slug',
        name: 'commercial-casestudy',
        component: Casestudy,
        meta: { title: 'Casestudy' },
    },
    {
        path: '/residential/:slug',
        name: 'residential-casestudy',
        component: Casestudy,
        meta: { title: 'Casestudy' },
    },
    // {
    //     path: '/:slug',
    //     name: 'casestudy',
    //     component: Casestudy,
    //     meta: { title: 'Casestudy' },
    // },
    { 
        path: "*", // ensure Error is last item
        name: 'error',
        component: ErrorPage,
        meta: { 
            title: 'Error.',
            hidden: true 
        },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        // return { x: 0, y: 0 }
    }
}