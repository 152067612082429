<!-- note that image helper has new prop "inlineStyles" -->

<template>
  <div class="ken-burns">
    <transition name="fade-v-long">
      <image-helper :src="slides[slideIndex]" :key="slideIndex" :inline-style="styles"/>
    </transition>
    <image-helper class="preload" v-if="preload" :src="slides[slideIndex + 1]" :key="slideIndex"/>
  </div>
</template>

<script>

  export default {
    props: {
      src: {
        type: Array,
        required: true
      },
      duration: {
        type: Number,
        default: 10000
      },
      randomise: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        slideIndex: 0,
        animationIndex: 0,
        preload: true
      }
    },
    computed: {
      animation() {
        return this.shuffle([
          'ken-burns-bottom-right',
          'ken-burns-top-right',
          'ken-burns-top-left',
          'ken-burns-bottom-left',
          'ken-burns-middle-left',
          'ken-burns-middle-right',
          'ken-burns-top-middle',
          'ken-burns-bottom-middle',
          'ken-burns-center'
        ])
      },
      slides() {
        return (this.randomise) ? this.shuffle(this.src) : this.src
      },
      styles() {
        return { animationName: this.animation[this.animationIndex], animationDuration: this.duration+2500 + 'ms' }
      }
    },
    methods: {
      changeSlide() {
        let slideCount = this.slides.length -1

        if(this.slideIndex === slideCount) {
          this.slideIndex = 0
        } else {
          this.slideIndex++
        }

        if(this.slideIndex === slideCount -1) {
          this.preload = false
        }

        this.animationIndex = Math.floor(Math.random() * (this.animation.length - 0) + 0)

        setTimeout(()=>{
          this.changeSlide()
        },this.duration);
      },
      shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array
      },
    },
    mounted() {
      setTimeout(()=>{
        this.changeSlide()
      },this.duration);
    }
  }
</script>