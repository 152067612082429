<template>
  <div id="casestudy" :key="project.id">

    <hero-helper>
      <video-helper v-if="heroVideo" :video="heroVideo"></video-helper>
      <image-helper v-else :src="heroImage" :alt="project.name"></image-helper>
    </hero-helper>

    <div class="full-container white-bg" ref="hero-mask">
      
      <div class="overview container grid sm-col-2">
        <div class="col title sm-span-2-col">
          <h1>{{project.name}}</h1>
          <h2 v-html="project.headline">{{project.headline}}</h2>
        </div>

        <div class="col primary">
          <div v-html="project.copy"></div>
          <a v-if="showLink" :href="'https://' + project.link" target="_blank">{{project.link}}</a>
        </div>
        
        <div class="col secondary">
          <ul>
            <li>Metrics</li>
            <li v-for="metric in project.metrics">{{metric}}</li>
          </ul>
          <ul>
            <li>Highlights</li>
            <li v-for="highlight in project.highlights">{{highlight}}</li>
          </ul>
          <ul v-if="associatedProjects">
            <li>Associated projects</li>
            <li v-for="project in associatedProjects">
              <project-link :to="project">
                <strong>{{project.shortname}}</strong> {{project.thumbnail_metric}}
              </project-link>
            </li>
          </ul>
        </div>
      </div>

      
      <div class="media-wrapper container">
        <secondary-media 
          v-for="filter in mediaFilters" 
          :project="project" 
          :filter="filter" 
          :title="title(filter)"
          :require-hover="requireHover"
          >
        </secondary-media>
      </div>
    </div>
    
    <casestudy-grid-footer :mode="project.parent" :bg="'blue'"></casestudy-grid-footer>

  </div>
</template>


<script>
  import HeroHelper from "~/components/HeroHelper.vue";
  import VideoHelper from "~/components/VideoHelper.vue";
  import ProjectTeaser from "~/components/ProjectTeaser.vue";
  import ProjectLink from "~/components/ProjectLink.vue";
  import SecondaryMedia from "~/components/SecondaryMedia.vue";
  import CasestudyGridFooter from "~/components/CasestudyGridFooter.vue";

  export default {
    components: { HeroHelper, VideoHelper, ProjectTeaser, ProjectLink, SecondaryMedia, CasestudyGridFooter },
    data(){
      return {
        hero: null
      }
    },
    computed: {
      project(){
        // Set route specific properties
        let project = this.$project(this.$route.params.slug)
        if (project){
          this.$store.commit('setTitle',project.shortname)
          this.$store.commit('setDescription',project.headline)
          this.$store.commit('setLoaded',true)
          return project
        } else {
          this.$router.push({ name: 'error' }) // 404 condition
        }
      },
      associatedProjects() {
        return this.$associatedProjects(this.project)
      },
      showLink() {
        return (this.project.link && this.project.parent === "residential")
      },
      projectMedia() {
        return this.$projectMedia(this.project)
      },
      heroVideo() {
        let output = this.projectMedia.hero.video
        return (output.length) ? output[0].src : false
      },
      heroImage() {
        let output = this.projectMedia.hero.image
        return (output.length) ? output[0] : false
      },
      mediaFilters() {
        let output = []
        Object.keys(this.projectMedia).forEach(function (key) {
          if(key !== 'hero') {
            output.push(key)
          }
        });
        return output
      },
      requireHover() {
        // if we have multiple secondary media require the mouse to be within the current media group to enable keyboard nav
        return this.mediaFilters.length > 1
      }
    },
    methods: {
      title(filter) {
        if((this.project.id === 'faringdon' || this.project.id === 'arbor-green') && filter === 'progress') {
          return 'Under Construction'
        }
      }
    },
  }
</script>