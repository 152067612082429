<template>
  <div id="error" class="container default-padding">
    <div class="grid one-col no-divider">
      <div v-if="is404" class="col">
        <inline-svg :src="$getImageSrc('icon.svg')"></inline-svg>
        <h1>Sorry, We can't seem to find the page you're looking for.</h1>
        <ul>
          <li>Here are some helpful links instead:</li>
          <li><router-link :to="{name: 'home'}">› Home</router-link></li>
          <li><router-link :to="{name: 'residential'}">› Residential Capability</router-link></li>
          <li><router-link :to="{name: 'commercial'}">› Commercial Capability</router-link></li>
        </ul>
      </div>
      <div v-if="is500">
        <inline-svg :src="$getImageSrc('icon.svg')"></inline-svg>
        <h1>Sorry, Our web site is unavilable due to an unscheduled outage.</h1>
        <ul>
          <li>We are investigating and expect to be back online soon, until then you can contact us here:</li>
          <li><a :href="'tel:' + contact.phone">› {{contact.phone}}</a></li>
          <li><a :href="'mailto:' + contact.email">› {{contact.email}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
  },
  props: {
    status: {
      type: Number,
      default: 500
    }
    },
    computed: {
      is404() {
        return this.status === 404
      },
      is500() {
        return this.status === 500
      },
      contact() {
        return this.$store.state.data.contact
      }
    }
  }
</script>