

<template>
  <video loop="" muted="" :autoplay="autoplay" webkit-playsinline="" playsinline="" ref="video"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 250,
    }">
    <!-- <source type="video/mp4" :src="'https://vultr.kichin.co' + videoSrc.mp4"> -->
    <!-- <source type="video/webm" :src="'https://vultr.kichin.co' + videoSrc.webm"> -->
    <source type="video/mp4" :src="videoSrc.mp4">
    <source type="video/webm" :src="videoSrc.webm">
  </video>
</template>


<script>

  export default {
    props: {
      video: {
        type: String,
        required: true
      },
      secondary: Boolean
    },
    data() {
      return {
        width: null,
        watchVisibility: false
      }
    },
    computed: {
      breakpoint() {
        let breakpoint = this.$store.state.breakpoint
        return (this.secondary && breakpoint === 'xl') ? 'lg' : breakpoint
      },
      videoSrc() {
        return this.$getVideoSrc(`${this.video}-${this.breakpoint}`)
      },
      autoplay() {
        return !this.secondary
      },
      route() {
        return this.$route.params.slug
      },
    },
    methods: {
      visibilityChanged(isVisible){
        if(this.watchVisibility) {
          if(isVisible) { 
            this.$refs.video.play();
          } else {
            this.$refs.video.pause();
          }
        }
      }
    },
    mounted() {
      setTimeout(()=>{
        // delay to prevent multiple firing on scrollTop
        this.watchVisibility = true
      },1250);
    }, 
    beforeDestroy() {
      // patch for nginx server lag
      this.$refs.video.pause();
      this.$refs.video.removeAttribute('src'); 
      this.$refs.video.load();
    }
  }
</script>