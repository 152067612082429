<template>
  <div id="home">

    <hero-helper>
      <ken-burns :src="kbSlides"></ken-burns>
    </hero-helper>

    <div class="intro fluid-container white-bg">
      <div class="container">
        <h1>Hughes Developments is a property development company based in Christchurch. Formed in 1986, our history now spans {{yearsCount}}+ years.</h1>
        <h2>We believe our long-lived success comes out of our simple commitment to act with integrity at all times.</h2>
        <h2>Our approach is always to take a long-term view of both our developments and our relationships. Our reputation today reflects decades of consistent delivery.</h2>
        <h3>Our specialties include building and marketing residential subdivisions, as well as delivering industrial and commercial property developments.</h3>
      </div>
    </div>

    <div class="big-links fluid-container grid sm-col-2 blue-bg" :class="{'can-hover' : hover}">
      <router-link :to="{name: 'residential'}" @click.native="scrollTop()" class="is-cover four-three">
        <ul>
          <li>Residential</li>
          <li>Over {{ calculateResidentialLots }} individual lots created</li>
          <li v-if="!hover">More ›</li>
        </ul>
        <image-helper :src="'faringdon-secondary-13'"/>
      </router-link>
      <router-link :to="{name: 'commercial'}" @click.native="scrollTop()" class="is-cover four-three">
        <ul>
          <li>Commercial</li>
          <li>Developments from 1000m² to 188ha</li>
          <li v-if="!hover">More ›</li>
        </ul>
        <image-helper :src="'pgg-wrightson-hero'" :position="'20% 50%'"/>
      </router-link>
    </div>
    
    <div class="team fluid-container white-bg">
      <div class="wide-container">
        <div class="team-intro container">
          <h2>Our Team</h2>
          <h3>Hughes Developments is comprised of a close-knit team of property experts, supported by select consultants as required. With 80 years of collective experience between us, we have the skills to see even the largest projects through despite our small size.</h3>
        </div>

        <div class="team-inner white-bg">
          <div class="container">
            <template v-if="xsMode">
              <accordion-item v-for="person in staff" :groupUid="uid" :singleMode="true" :class="{'no-toggle' : person.hidexs}">
                <template v-slot:title>
                  <staff-helper :member="person"></staff-helper>
                </template>
                <template v-slot:content>
                  <p class="bio">{{person.bio}}</p>
                </template>
              </accordion-item>
            </template>

            <staff-helper v-else :bio="true" :img="true" class="grid sm-col-3"></staff-helper>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import HeroHelper from "~/components/HeroHelper.vue";
  import KenBurns from "~/components/KenBurns.vue";
  import AccordionItem from "~/components/AccordionItem.vue";
  import StaffHelper from "~/components/StaffHelper.vue";
  var VueScrollTo = require('vue-scrollto');

  export default {
    components : { HeroHelper, KenBurns, AccordionItem, StaffHelper },
    data() {
      return {
        uid: Date.now() + Math.random(),
      }
    },
    computed: {
      xsMode() {
        return this.$store.state.breakpoint === 'xs'
      },
      hover() {
        return (window.matchMedia( "(hover: none)" ).matches) ? false : true
      },
      staff() {
        return this.$store.state.data.staff
      },
      yearsCount() {
        let date = new Date()
        return date.getUTCFullYear() - 1986 
      },
      kbSlides() {
        let output = []
        let self = this
        let projects = this.$projects('residential')

        projects.forEach(function (project, index) {
          let media = self.$projectMedia(project)
          output.push(media.hero.image[0])
        });

        return output
      },
      calculateResidentialLots() {
        let totalLots = 0;

        this.$projects('residential').forEach(project => {
          if (!project.parent_project) {
            const metric = project.thumbnail_metric;
            if (metric) {
              // Extract numbers from the metric string
              const numbers = metric.match(/\d+/g);
              if (numbers && numbers.length > 0) {
                // Parse the first number found and add it to the total
                totalLots += parseInt(numbers[0], 10);
              }
            }
          }
        });

        // Round down to the nearest 50
        const roundedTotal = Math.floor(totalLots / 50) * 50;
        return roundedTotal;
      }
    },
    methods: {
      scrollTop() {
        VueScrollTo.scrollTo('body')
      }
    }
  }
</script>