<template>
  <div v-if="mode" id="menu-overlay" :class="{'active':active}" :style="xsMenuStyle">
    <div class="container">

      <template v-if="xsMode">

        <div class="menu-overlay-inner" ref="xsInner">
          <accordion-item v-for=" thisMode in modes" :groupUid="uid" :singleMode="true" :titleClick="true">
            <template v-slot:title>
              {{thisMode}}
            </template>
            <template v-slot:content>
              <ul>
                <li class="capability"><router-link :to="{name: thisMode}" @click.native="scrollToTop(), close()">{{thisMode}} Capability</router-link></li>
                <li class="cs-heading">Case studies</li>
                <li v-for="project in $projects(thisMode)">
                  <project-link :to="project" v-html="$projectTitle(project)" @click.native="scrollToTop(), close()"></project-link>
                </li>
              </ul>
            </template>
          </accordion-item>

          <accordion-item :groupUid="uid" :singleMode="true" :titleClick="true" class="contact">
            <template v-slot:title>
              Contact
            </template>
            <template v-slot:content>
              <contact-helper></contact-helper>
              <a :href="contact.gmaps" class="map">
                <inline-svg v-if="!streetView" :src="$getImageSrc('map')"></inline-svg>
                <div v-else class="img-wrapper is-cover">
                  <image-helper :src="'office'"></image-helper>
                </div>
              </a>
              <span @click="streetView = !streetView">View {{mapMode}}</span>
            </template>
          </accordion-item>
        </div>

      </template>


      <template v-else>
        <div class="menu-overlay-inner">
          <div class="close-wrapper">
            <div class="close" @click="close()"></div>
          </div>

          <transition-group name="fade">

            <div v-if="mode !== 'contact'" :class="mode" class="grid col-2 gap-60" :key="mode">
              <div class="col intro">
                <h4>{{mode}} Capability</h4>
                <h2>{{content.headline}}</h2>
                <div class="copy" v-html="content.copy"></div>
                <router-link :to="{name: mode}" @click.native="scrollToTop(), close()">Learn more ›</router-link>
              </div>
              <casestudy-grid 
                :id="'cs-grid'" 
                :ref="'cs-grid'" 
                :mode="mode" 
                :cols="'col-2'" 
                :key="mode" 
                :bg="'blue'" 
                :class="csClasses">
              </casestudy-grid>
              
            </div>
            
            <div v-else class="contact" :key="active">
              <div class="col">
                <contact-helper></contact-helper>
              </div>
              <div class="col">
                
                <a :href="contact.gmaps" class="map">
                  <div class="img-wrapper is-cover">
                    <image-helper :src="'office'"></image-helper>
                  </div>
                </a>

                <a :href="contact.gmaps" class="map">
                  <inline-svg :src="$getImageSrc('map')"></inline-svg>
                </a>

              </div>
            </div>

          </transition-group>

        </div>
      </template>

    </div>

    <div class="mask" @click="close()"></div>
  </div>
</template>


<script>

  import ContactHelper from "~/components/ContactHelper.vue";
  import AccordionItem from "~/components/AccordionItem.vue";
  import CasestudyGrid from "~/components/CasestudyGrid.vue";
  import ProjectLink from "~/components/ProjectLink.vue";
  import StaffHelper from "~/components/StaffHelper.vue";
  import { EventBus } from '/event-bus.js';
  var VueScrollTo = require('vue-scrollto');



  export default {
    components : { ContactHelper, AccordionItem, CasestudyGrid, ProjectLink, StaffHelper },
    props: {
      mode: [String, Boolean],
    },
    data(){
      return {
        uid: Date.now() + Math.random(),
        modes: ['residential','commercial'],
        active: false,
        csClasses: [],
        xsMenuStyle: null,
        streetView: false,
      }
    },
    computed: {
      xsMode() {
        return this.$store.state.breakpoint === 'xs' || this.$store.state.breakpoint === 'sm'
      },
      contact() {
        return this.$store.state.data.contact
      },
      staff() {
        return this.$store.state.data.staff
      },
      content() {
        if(this.mode === 'residential') {
          return this.$store.state.data.residential
        } else if (this.mode === 'commercial') {
          return this.$store.state.data.commercial
        }
      },
      mapMode() {
        return (this.streetView) ? 'street map' : 'office'
      }
    },
    methods: {
      close() {
        this.active = false
        EventBus.$emit('hamburger', false); // close hamburger early
        setTimeout(()=>{
          EventBus.$emit('closeOverlay', true);
        },500);
      },
      goto(name) {
        VueScrollTo.scrollTo('body');
        if(name !== this.$route.name) {
          this.$router.push({name:name})
        }
        this.close()
      },
      scrollToTop() {
        VueScrollTo.scrollTo('body');
      },
      checkScroll() {
        if(this.active && this.mode && this.mode !== 'contact' && !this.xsMode) {
          let csGrid = this.$refs['cs-grid'].$el.children[1]
          let hasScroll = csGrid.scrollHeight - csGrid.clientHeight

          if(hasScroll > 1) {
            csGrid.addEventListener('scroll', this.handleScroll);
            this.handleScroll();
          }
        }
      },
      handleScroll() {
        let target = document.getElementById('cs-grid').childNodes[2]
        let maskHeight = 60
        let output = ['has-scroll']

        if(target.scrollTop + target.clientHeight >= target.scrollHeight - maskHeight) {
          output.push('mask-top')
        }
        if(target.scrollTop <= maskHeight) {
          output.push('mask-bottom')
        }

        this.csClasses = output
      },
      xsMenuHeight(emit) {
        if(this.xsMode) {
          let activeHeight = 165
          let output = 0

          if(this.active) {
            output = activeHeight

            if(emit && emit.status === true) {
              output = emit.height + activeHeight
            } 
          }
          this.xsMenuStyle = { height : output + 'px' }
        }
      }
    },
    watch: {
      // $route() {
      //   this.close()
      // },
      mode() {
        setTimeout(()=>{ // wait out transition
          this.checkScroll()
        },500);
      },
      active() {
        this.xsMenuHeight()
      }
    },
    mounted() {
      setTimeout(()=>{ // no idea why we need this delay, nextTick wont work
        this.active = true
        this.checkScroll()
      },5);

      EventBus.$on('startCloseAnimation', (emit) => {
        this.close();
      });

      EventBus.$on('accordionChange', (emit) => {
        this.xsMenuHeight(emit)
      });
    }
  }
</script>