import Images from './assets/img/**/*.*'
import Videos from './assets/video/**/*.*'
import Store from './store.js'

var Helpers = {}

Helpers.getImageSrc = (target) => {
  return Helpers.getSrc(target,Images);
}

Helpers.getVideoSrc = (target) => {
  return Helpers.getSrc(target,Videos);
}

Helpers.getSrc = (target, src) => {
  if(target !== undefined) {
    if(target.charAt(0) === '/') {
      target = target.substr(1); // remove leading slash if it was left in by accident
    }
    target = target.split('.')[0] // remove ext
    target = target.split('/') // split on slashes
  }
  target.forEach(function (value, index) {
    src = src[value]
  });

  if(Object.keys(src).length === 1) {
    return src[Object.keys(src)[0]]; // returns string
  } else {
    return src // returns object
  }
}

Helpers.fullUrl = (target) => {
  if(target !== undefined) {
    return Store.state.fullURL + '/' + target
  }
}

Helpers.formatUrl = (url) => {
  if(url !== undefined) {
    if(url.startsWith('https://')) {
      url = url.split('https://')[1]
    }
    if(url.startsWith('http://')) {
      url = url.split('http://')[1]
    }
    if(url.startsWith('www.')) {
      url = url.split('www.')[1]
    }
    if(url.includes('/')) {
      url = url.split('/')[0];
    }
    return url
  }
}

Helpers.toKebab = (value) => {
  if(typeof value !== "string") {
    return value
  } else {
  	value = value.toLowerCase()
  	value = value.replace(/[^a-zA-Z0-9 ]/g, '-') // remove specials
  	value = value.replace(/ /g, '-') // remove spaces
  	value = value.replace(/-{1,}/g, '-') // remove multiple dashes
  	return value
  }
}


Helpers.formatTel = (number) => {
  if(number === undefined) {
    return false
  }

  if(number.startsWith('0800')) {
    return number
  }
  // number = number.replace(/\s/g, '')
  if(number.startsWith('0')) {
    number = number.substring(1)
  }
  number = '+64 ' + number
  return number
}


Helpers.is = (breakpoint) => {
  return Store.state.breakpoint === breakpoint
}


Helpers.projects = (filter) => {
  let projects = Store.state.data.projects
  let output = []

  projects.forEach(function (value, index) {
    if (value.parent == filter && filter) {
      output.push(value)
    }
  });

  return output
}


Helpers.project = (id) => {
  let projects = Store.state.data.projects
  let output = Object

  projects.forEach(function (value, index) {
    if (value.id == id && id) {
      output = value
    }
  });
  return output
}


Helpers.projectMedia = (project) => {
  let output = {}
  let targets = ['videos','images']

  targets.forEach(function (value, index) {
    let target = project[value]

    Object.keys(target).forEach(function (key) {
      if(!output[key]) {
        output[key] = { 
          'video' : [], 
          'image' : [] 
        }
      }
      
      for (let i = 0; i < target[key]; i++) {
        if(value === 'images') {
          let src = `${project.id}-${key}`
          if(key !== 'hero') { 
            src = src + `-${i+1}`
          }
          output[key].image.push(src)
        } else {
          let src = `${project.id}-${key}`
          let poster = `${project.id}-${key}-poster`
          if(key !== 'hero') {
            src = src + `-${i+1}`
            poster = poster + `-${i+1}`
          }
          output[key].video.push({
            'src' : src,
            'poster' : poster
          })
        }
      }
      
    });
  });
  return output
}


Helpers.projectTitle = (project) => {
  if(project.parent_project){
    return `<ul class="title"><li>${project.shortname}, ${Helpers.project(project.parent_project).shortname}</li><li>${project.thumbnail_metric}</li></ul>`
  } else {
    return `<ul class="title"><li>${project.shortname}</li><li>${project.thumbnail_metric}</li></ul>`
  }
}


Helpers.associatedProjects = (project) => {
  let projects = Store.state.data.projects
  let output = []

  if(project.parent_project) { // if project has a parent
    projects.forEach(function (value, index) {
      if(value.id !== project.id) { // exclude current project
        if(value.id === project.parent_project){
          output.unshift(value) // push parent project to the front of the array
        } else if(value.parent_project === project.parent_project){
          output.push(value) // push other associated projects after parent
        }
      }
    });
  } else { // check if project has children
    projects.forEach(function (value, index) {
      if(value.parent_project === project.id) {
        output.push(value)
      }
    });
  }

  return (output.length) ? output : false
}




const install = function(Vue, options){
	Vue.prototype.$getImageSrc 				= Helpers.getImageSrc
	Vue.prototype.$getVideoSrc 				= Helpers.getVideoSrc
  Vue.prototype.$fullUrl            = Helpers.fullUrl
	Vue.prototype.$formatUrl 				  = Helpers.formatUrl
	Vue.prototype.$toKebab 						= Helpers.toKebab
	Vue.prototype.$formatTel 					= Helpers.formatTel
	Vue.prototype.$wrapText						= Helpers.wrapText
  Vue.prototype.$is                 = Helpers.is
  Vue.prototype.$projects           = Helpers.projects
  Vue.prototype.$project            = Helpers.project
  Vue.prototype.$projectTitle       = Helpers.projectTitle
  Vue.prototype.$associatedProjects      = Helpers.associatedProjects
  Vue.prototype.$projectMedia      = Helpers.projectMedia
}


Helpers.install = install
export default Helpers;

