<template>
  <div class="contact-details">

    <template v-if="shortform">
      <ul>
        <li><a :href="contact.gmaps" target="_blank">{{contact.physical1}}, {{contact.physical2}}, {{contact.physical3}}</a></li>
        <li v-if="contact.phone"><a :href="'tel:' + contact.phone">{{contact.phone}}</a></li>
        <li><a :href="'mailto:' + contact.email">{{contact.email}}</a></li>
      </ul>
    </template>

    <template v-else>
      <ul v-if="showItem('physical')">
        <li>{{contact.physical1}}</li>
        <li>{{contact.physical2}}</li>
        <li>{{contact.physical3}}</li>
      </ul>
      
      <ul v-if="showItem('postal')">
        <li>{{contact.postal1}}</li>
        <li>{{contact.postal2}}</li>
        <li>{{contact.postal3}}</li>
      </ul>

      <ul v-if="showItem('details')">
        <li v-if="contact.phone"><a :href="'tel:' + contact.phone">{{contact.phone}}</a></li>
        <li><a :href="'mailto:' + contact.email">{{contact.email}}</a></li>
      </ul>
    </template>
  </div>
</template>


<script>

  export default {
    props: {
      shortform: Boolean,
      show: [String, Array]
    },
    data(){
      return {
      }
    },
    computed: {
      contact() {
        return this.$store.state.data.contact
      },
    },
    methods: {
      showItem(filter) {
        if(this.show) {
          return (this.show === filter || this.show.includes(filter))
        } else {
          return true
        }
      }
    },
  }
</script>