
<template>
  <div class="staff">
    <ul class="staff-member" v-if="staff" v-for="person in staff">
      <li class="name">{{person.name}}</li>
      <li v-if="img" class="img">
        <image-helper :src="'staff-' + person.id"></image-helper>
      </li>
      <li v-if="bio" class="bio">{{person.bio}}</li>
      <li v-if="person.phone" class="phone"><a :href="'tel:' + person.phone">{{person.phone}}</a></li>
      <template v-if="person.email">
        <li v-if="fullemail" class="full-email"><a :href="'mailto:' + person.email">{{person.email}}</a></li>
        <li v-else class="email"><a :href="'mailto:' + person.email">{{person.shortname}}</a></li>
      </template>
    </ul>
  </template>
</template>


<script>

  export default {
    props: {
      member: Object,
      img: false,
      bio: false,
      fullemail: false
    },
    data(){
      return {
      }
    },
    computed: {
      staff() {
        if (this.member) {
          let array = []
          array.push(this.member)
          return array
        } else {
          return this.$store.state.data.staff
        }
      }
    },
    methods: {
    }
  }
</script>