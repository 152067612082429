<template>
  <div id="capability" :key="xsMode">

    <hero-helper>
      <ken-burns :src="kbSlides"></ken-burns>
    </hero-helper>

    <div class="content-wrapper fluid-container white-bg">

      <div class="container grid sm-col-2">
        <div class="col intro">
          <h1>{{mode}} capability</h1>
          <h2>{{content.headline}}</h2>
          <div class="copy" v-html="content.copy"></div>
        </div>

        <project-teaser v-if="xsMode" :project="projects[0]"></project-teaser>
        <project-teaser v-else :project="projects[1]" class="bottom"></project-teaser>


        <template v-if="!xsMode">
          <project-teaser v-if="isResidential" :project="projects[2]" class="top"></project-teaser>

          <template v-if="isCommercial">
            <project-teaser class="col sm-span-2-col" :project="projects[0]"></project-teaser>
            <project-teaser :project="projects[2]"></project-teaser>
          </template>
        </template>
       

        <div class="col secondary">
          <h2>{{content.subheading}}</h2>
          <project-teaser v-if="xsMode" :project="projects[1]"></project-teaser>
          <ul class="highlights">
            <template v-for="highlight in content.highlights">
              <li>{{highlight[0]}}</li>
              <li>{{highlight[1]}}</li>
            </template>
          </ul>
        </div>

        <project-teaser v-if="xsMode" :project="projects[2]"></project-teaser>
        <project-teaser v-else-if="isResidential" class="col sm-span-2-col" :project="projects[0]"></project-teaser>

      </div>
    </div>

    <casestudy-grid-footer :mode="mode"></casestudy-grid-footer>

  </div>
</template>


<script>

  import HeroHelper from "~/components/HeroHelper.vue";
  import KenBurns from "~/components/KenBurns.vue";
  import CasestudyGridFooter from "~/components/CasestudyGridFooter.vue";
  import ProjectTeaser from "~/components/ProjectTeaser.vue";

  export default {
    components: { HeroHelper, KenBurns, CasestudyGridFooter, ProjectTeaser },
    data(){
      return {
      }
    },
    computed: {
      mode() {
        return this.$route.name
      },
      content() {
        return this.$store.state.data[this.mode]
      },
      projects() {
        return this.$projects(this.mode)
      },
      xsMode() {
        return this.$store.state.breakpoint === 'xs'
      },
      isCommercial() {
        return this.mode === 'commercial'
      },
      isResidential() {
        return this.mode === 'residential'
      },
      kbSlides() {
        let output = []
        let self = this
        let projects = this.$projects(this.mode)

        projects.forEach(function (project, index) {
          let media = self.$projectMedia(project)
          output.push(media.hero.image[0])
        });

        return output
      },
    },
    mounted() {
    }
  }
</script>