<!-- display order is based on json data order, ammend accordingly -->

<template>
  <div class="casestudy-grid">
    <h2 v-if="title">{{mode}}<br/> Case Studies</h2>
    <h4 v-else>Case Studies</h4>
    <div class="projects-wrapper grid" :class="cols">
      <project-teaser @click.native="click()" v-for="project in $projects(mode)" :project="project" :bg="bg"></project-teaser>
    </div>
  </div>
</template>


<script>

  import ProjectTeaser from "~/components/ProjectTeaser.vue";
  import { EventBus } from '/event-bus.js';

  export default {
    components : { ProjectTeaser },
    props: {
      mode: String,
      title: Boolean,
      cols: String,
      bg: String,
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
      click() {
        EventBus.$emit('startCloseAnimation', true);
      }
    }
  }
</script>