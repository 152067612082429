<!-- display order is based on json data order, ammend accordingly -->

<template>
  <div id="casestudy-footer" class="fluid-container" :class="bg+'-bg'">
    <div class="container">
      <casestudy-grid 
        :mode="mode" 
        :title="true" 
        :cols="'col-2 md-col-3'"
        :bg="bg"> 
      </casestudy-grid>
    </div>
  </div>
</template>


<script>

  import CasestudyGrid from "~/components/CasestudyGrid.vue";

  export default {
    components : { CasestudyGrid },
    props: {
      mode: String,
      bg: String,
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>