
<template>
  <img ref="img"
       class="img-loading" 
       src='/assets/img/pixel.png'
       :alt="alt">
</template>


<script>
  export default {
    props: {
      src: String,
      alt: String,
      crop: { // Prop only affects XS view as its our only fluid breakpoint, Images on all other breakpoints will be cropped regardless (unless using override)
        type: Boolean,
        default: true,
      },
      override: {
        type: String
      }
    },
    data () {
      return {
        breakpoint: ''
      }
    },
    methods: {
      srcset(append) {        
        let image = this.$refs.img

        if(image && this.src) {

          //let src = this.$store.state.fullURL + this.$store.state.imagePath + this.$getImageSrc(this.src) // revvd version
          let src = this.$store.state.fullURL + this.$store.state.imagePath + this.src + '-1' // unrevvd
          
          // console.log(src)

          // allowance for manual override
          if(this.override) {
            // Dual format
            // srcset = src + this.override + '&fm=webp', '
            // srcset += src + this.override + '&fm=jpg' 
            // image.srcset = srcset

            // Single format
            image.srcset = src + this.override 

            return
          }

          // setup
          let oldSrcset = image.srcset
          let breakpoint = this.breakpoint
          let width = Math.floor(image.offsetWidth)
          let height = Math.floor(image.offsetHeight)
          let dpr = (Math.round(window.devicePixelRatio * 4) / 4).toFixed(2); // round up to nearest 0.25, could move to 0.5 if required
          let actualSize = Math.floor(width * dpr)
          let crop = '&fit=crop'
          let srcset = ''
          let csrfToken = this.$store.state.csrfToken

          // set hardcoded sizes for XS 
          // use height / width to get ratio, then use to get height for set width (375px / 575px)
          if(breakpoint === 'xs') {
            if(this.crop) {
              if(window.innerWidth <= 375) {
                height = Math.ceil(375 * (height/width))
                width = 375
              } else {
                height = Math.ceil(575 * (height/width))
                width = 575
              } 
              actualSize = Math.floor(width * dpr)
            } else {
              // if not cropping just give them the image at its current size
              // for our purposes (MGH 2.0) we can assume that images will likely need to match height, not width.
              crop = '&fit=fill'
              width = ''
              // dont update actual size, its a close enough approximation
            }
          }

          // Dual format
          srcset = src + '?w=' + width + '&h=' + height + '&dpr=' + dpr + '&fm=webp' + crop + ' ' + actualSize + 'w, '
          srcset += src + '?w=' + width + '&h=' + height +  '&dpr=' + dpr + '&fm=jpg' + crop + ' ' + actualSize + 'w' 

          // Single format
          // srcset += src + '?s=' + csrfToken + '&w=' + width + '&h=' + height + '&dpr=' + dpr + crop + ' ' + actualSize + 'w' 

          // append srcset if we have an existing srcset value and the current width dosent already exist
          if(append && oldSrcset) {
            if(oldSrcset.includes('w=' + width)) {
              srcset = oldSrcset
            } else {
              srcset = oldSrcset + ', ' + srcset
            }
          }

          // inject
          image.srcset = srcset
        }
      },
      resize(e) {
        if(this.breakpoint !== this.$store.state.breakpoint || window.innerWidth === 376) {
          this.breakpoint = this.$store.state.breakpoint
          this.srcset(true)
        }
      },
    },
    watch: {
      src: function(val) {
        this.srcset()
      }
    },
    mounted() {
      this.breakpoint = this.$store.state.breakpoint
      this.srcset();
      window.addEventListener("resize", this.resize);
    },
  }
</script>