
<template>
  <router-link :to="{name: project.parent + '-casestudy', params: { slug: project.id}}" @click.native="scrollTop()">
    <slot></slot>
  </router-link>
</template>


<script>
  var VueScrollTo = require('vue-scrollto');

  export default {
    props: {
      to: Object,
    },
    data(){
      return {
      }
    },
    computed: {
      project() {
        return this.to
      }
    },
    methods: {
      scrollTop() {
        VueScrollTo.scrollTo('body')
      }
    }
  }
</script>