<template>
  <default v-if="loaded">
    <router-view></router-view>
  </default>
</template>


<script>
  import Default from '~/components/layouts/Default.vue'

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
      }
    },
    computed: {
      loaded() {
        return this.$store.state.loaded
      },
    },
  	methods: {
  		setup() {
  			this.cssVars();
  			console.log('%cDesign : https://bathgatedesign.co.nz', 'color: #000000; font-weight: bold;');
        console.log('%cDevelopment: https://kichin.co', 'color: #000000; font-weight: bold;');
  			console.log('–');
  			this.breakpointCheck();
  		},
  		resize(e) {
  			this.breakpointCheck();
  			this.cssVars();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199){
          breakpoint = 'lg';
        } else {
          breakpoint = 'xl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          console.log('Breakpoint : ' + breakpoint)
        }
      },
      cssVars() {
        // Handle VH units : get the viewport height and multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
      }
    },
    metaInfo() {
      return {
        title: this.$store.state.titlePrefix + this.$store.state.title,
        meta: [
          {
          name: 'description',
          content: this.$store.state.description
          },
          {
          property: 'og:title',
          content: this.$store.state.title
          },
          {
          property: 'og:description',
          content: this.$store.state.description
          },
        ],
        link: [
            {rel: 'canonical', href: this.$store.state.fullURL + this.$route.fullPath}
          ]
      }
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
      this.$store.commit('setLoaded', true);
    }
  }
</script>