<template>
  <header v-scroll-lock="overlay" :class="{'active' : overlay}">
    <div class="outer full-container">
      <div class="inner container">
        <router-link :to="{name: 'home'}" class="logo" @click.native="homeClick()">
          <inline-svg :src="$getImageSrc('logo.svg')">
        </router-link>
        <ul v-if="!xsMode" class="menu">
          <li v-for="item in menu" @click="triggerOverlay(item)" :class="isActive(item)">{{item}}</li>
        </ul>
        <div v-if="xsMode" @click="triggerOverlay(true)" class="hamburger" :class="{'active' : hamburgerStatus}"></div>
      </div>
    </div>

    <menu-overlay :mode="overlay" :key="overlayKey"></menu-overlay>

  </header>
</template>


<script>

  import MenuOverlay from "~/components/MenuOverlay.vue";
  import { EventBus } from '/event-bus.js';
  var VueScrollTo = require('vue-scrollto');

  export default {
    components : { MenuOverlay },
    data(){
      return {
        overlay: false,
        menu: ['residential','commercial','contact'],
        hamburger: false
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      isHome() {
        return (this.$route.name === 'home' && this.overlay === false)
      },
      xsMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm'
      },
      overlayKey() {
        return this.overlay === false
      },
      hamburgerStatus() {
        return this.mode || this.hamburger
      }
    },
    methods: {
      triggerOverlay(mode){
        if(this.overlay === mode || this.overlay === true) {
          EventBus.$emit('startCloseAnimation', true);
        } else {
          EventBus.$emit('startFadeAnimation',mode)
          this.overlay = mode
          this.hamburger = true
        }
      },
      isActive(item){
        let classes = []
        if(this.overlay === item) {
          classes.push('overlay-active')
        }
        if(this.$route.name === item || this.$route.name === item + '-casestudy') {
          if(!this.overlay) {
            classes.push('active')
          }
        }
        return classes
      },
      keypress(event){
        if (event.key == 'Escape') {
          this.overlay = false
        }
      },
      homeClick() {
        if(this.$route.name === 'home') {
          EventBus.$emit('startCloseAnimation', true);
          VueScrollTo.scrollTo('body')
        }
      }
    },
    watch: {
      breakpoint() {
        this.overlay = false
      }
    },
    created() {
      window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          EventBus.$emit('startCloseAnimation', true);
        }
      });

      EventBus.$on('closeOverlay', (emit) => {
        this.overlay = false
      });
      EventBus.$on('hamburger', (emit) => {
        this.hamburger = false
      });
    },
  }
</script>