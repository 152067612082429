
<template>
  <project-link :to="project" class="project-teaser" :class="classes" v-observe-visibility="{callback: vChanged, throttle: 300 }">
    <ul>
      <li class="img-wrapper is-cover sixteen-nine"><image-helper :src="$projectMedia(project).hero.image[0]"/></li>
      <li v-html="$projectTitle(project)"></li>
    </ul>
  </project-link>
</template>


<script>
  import ProjectLink from "~/components/ProjectLink.vue";

  export default {
    components : { ProjectLink },
    props: {
      project: Object,
      bg: String,
      vWatch: false
    },
    data(){
      return {
        vStatus: false
      }
    },
    computed: {
      classes() {
        let output = [this.bg,this.vStatus]
        if(this.vWatch) {
          output.push('v-watch')
        }
        return output
      }
    },
    methods: {
      vChanged(isVisible) {
        if(isVisible && this.vWatch) {
          this.vStatus = 'visible'
        }
      }
    },
  }
</script>