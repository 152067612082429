<template>
  <footer class="full-container" :class="{'white' : whiteMode}">
  	<div class="container">
      <inline-svg :src="$getImageSrc('icon.svg')" @click="scrollToTop()"></inline-svg>
  		<contact-helper :shortform="true"></contact-helper>
  	</div>
  </footer>
</template>


<script>
  import ContactHelper from '~/components/ContactHelper'
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { ContactHelper },
    data(){
      return {
      }
    },
    computed: {
      whiteMode() {
        return this.$route.name === 'residential' || this.$route.name === 'commercial'
      }
    },
    methods: {
      scrollToTop() {
        VueScrollTo.scrollTo('body')
      }
    }
  }
</script>