import Data from './data.json';

export default {
  state: {
    loaded: false,
    breakpoint: '',
    fullURL: 'https://hughesdevelopments.kichin.co',
    imagePath: '/assets/img/', 
    data: Data,
    titlePrefix: 'Hughes Developments : ',
    title: '',
    description: '',
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setTitle (state, payload){
      state.title = payload
    },
    setDescription (state, payload){
      state.description = payload
    },
  },
  actions: {
  }
}